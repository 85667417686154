import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"


const FluidImage = ({image, mobileImage, className, ...props}) => {
  if (!image.childImageSharp && image.extension === "svg") {
    return <img src={image.publicURL} alt={image.alt} />
  }

  if (!!image || !!image.childImageSharp) {
    if (!!mobileImage && !!mobileImage.childImageSharp) {
      // Image with art-directed mobile image for smaller screens
      let {alt="", childImageSharp} = image
      const imageSources = withArtDirection(
        getImage(mobileImage.childImageSharp.gatsbyImageData),
        [
          {
            media: "(min-width: 800px)",
            image: getImage(childImageSharp.gatsbyImageData),
          },
        ]
      )

      return (
        <GatsbyImage {...props} image={imageSources} alt={alt} className={className}/>
      )
    } else {
      let {alt="", childImageSharp} = image
      return (
        <GatsbyImage {...props} image={childImageSharp.gatsbyImageData} alt={alt} className={className}/>
      )
    }
  } else {
    return null
  }
}


FluidImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  mobileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
}


export default FluidImage
