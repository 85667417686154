import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import BlogList from "../components/BlogList/BlogList"
import Heading from "../components/Heading/Heading"
import Layout from "../components/Layout/Layout"
import Section from "../components/Section/Section"
import Segment from "../components/Segment/Segment"


class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? "" : "s"
    } tagged with “${tag}”`

    return (
      <Layout>
        <Helmet title={`Blog Articles Tagged with '${tag}' | ${title}`} />
        <Section>
          <Segment>
            <Heading level={1} modifiers={["major"]}>{tagHeader}</Heading>
          </Segment>
        </Section>
        <BlogList posts={posts}/>
      </Layout>
    )
  }
}


TagRoute.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
      totalCount: PropTypes.number,
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
      })
    })
  })
}


export default TagRoute

export const tagPageQuery = graphql`query TagPage($tag: String, $deployDate: Date!) {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(
    limit: 1000
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {tags: {in: [$tag]}, templateKey: {eq: "blog-post"}, visible: {eq: true}, date: {lte: $deployDate}}}
  ) {
    totalCount
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          summary
          listingImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}`
